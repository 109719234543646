import styled from "styled-components";
import { IoMdClose } from "react-icons/io";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  left: 0%;
  top: 0%;
  z-index: 14;
`;

export const ModalWrapper = styled.div`
  width: 80%;
  max-width: 720px;
  height: auto;
  max-height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.8);
  background-color: transparent;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 15;
  border-radius: 1rem;

  @media (min-width: 360px) {
    width: 180%;
    right: 20vw;
  }

  @media (min-width: 400px) {
    width: 180%;
    right: 20vw;
  }

  @media (min-width: 1280px) {
    width: 80%;
    left: 10%;
  }
`;

export const ModalImg = styled.img`
  width: 100%;
  max-width: 1080px;
  height: auto;
  max-height: 1080px;
  border-radius: 1rem;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;

  p {
    margin-bottom: 1rem;
  }

  button {
    padding: 10px 24px;
    background-color: #141414;
    color: #fff;
    border: none;
  }
`;

export const CloseModalButton = styled(IoMdClose)`
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  padding: 0;
  z-index: 10;
  color: #fff;
  background-color: red;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  transition: 1s ease-in-out 0.1s;

  &:hover {
    filter: brightness(0.8);
  }
`;
