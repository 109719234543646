import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Loading, Template } from "components";
import { apiFinancingStatus } from "services/data";
import { ButtonGigi } from "styles";
import LogoPrefeitura from "../../../assets/images/icons/logo_prefeitura.svg";
import LogoComercial from "../../../assets/images/icons/logo_comercial.svg";
import LogoMarketing from "../../../assets/images/icons/logo_marketing.svg";
import LogoAceleradores from "../../../assets/images/icons/logo_acelerador.svg";
import { useKeycloak } from "@react-keycloak/web";
import jwtDecode from "jwt-decode";
import { IToken } from "interfaces/User";

const BemVindo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [financingStatus, setFinancingStatus] = useState(false);
  const { keycloak } = useKeycloak();
  const user: IToken = jwtDecode(keycloak.token || "");
  const history = useHistory();

  async function fetchFinancingStatus() {
    const requestFinancingStatus = await apiFinancingStatus.getStatus();
    setFinancingStatus(requestFinancingStatus.data.debito);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchFinancingStatus();
  }, []);

  const isClientFarmaHub = useMemo(() => {
    if (user) {
      if (user.isFarmaHub) {
        if (Boolean(user.isFarmaHub) === true) {
          return true;
        }
      }
      return false;
    }

    return false;
  }, [user]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className="button-content">
            <ButtonGigi
              type="button"
              bgColor="red"
              onClick={() => history.push("administrativo")}
            >
              <img src={LogoPrefeitura} alt="" />
            </ButtonGigi>
            <ButtonGigi
              type="button"
              bgColor="red"
              onClick={() => history.push("anjo")}
            >
              <img src={LogoAceleradores} alt="" />
            </ButtonGigi>
            <ButtonGigi
              type="button"
              bgColor="red"
              onClick={() => history.push("comercial")}
            >
              <img src={LogoComercial} alt="" />
            </ButtonGigi>
            <ButtonGigi
              type="button"
              onClick={() => history.push("marketing")}
              bgColor={isClientFarmaHub ? "disabled" : "red"}
              disabled={isClientFarmaHub}
            >
              <img src={LogoMarketing} alt="" />
            </ButtonGigi>
            <ButtonGigi
              type="button"
              bgColor={financingStatus ? "disabled" : "red"}
              disabled={financingStatus ? true : false}
              onClick={() => history.push("treinamentos")}
            >
              TREINAMENTOS
            </ButtonGigi>
          </div>
        </Template>
      )}
    </>
  );
};

export default BemVindo;
